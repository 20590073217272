/** @jsxImportSource theme-ui */
import { useRef } from 'react'
import SignaturePad from 'react-signature-pad-wrapper'
import Button from 'src/app/components/Shared/Elements/Button'
import ModalBody from 'src/app/components/Shared/Elements/ModalBody'
import ModalFooter from 'src/app/components/Shared/Elements/ModalFooter'
import Icon from 'src/app/components/Shared/Icon'
import ModalButton from 'src/app/components/Shared/ModalButton'
import { Box, Flex } from 'theme-ui'

const SignatureInputModal = ({ signature, onEnd }) => {
  const signaturePadRef = useRef(null)

  const handleEnd = () => {
    const signatureImage = signaturePadRef.current?.toDataURL()
    onEnd(signatureImage)
  }

  const onClear = () => {
    signaturePadRef.current?.clear()
    onEnd(null)
  }

  return (
    <>
      <ModalButton
        CustomButtonComponent={props => (
          <Box
            {...props}
            sx={{
              width: '130px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: 'gray300',
              borderRadius: '5px',
              alignItems: 'center',
              bg: 'white',
              textAlign: 'center',
              position: 'relative',
              color: 'black'
            }}
          >
            {signature ? (
              <>
                <img
                  src={signature}
                  alt="signature"
                  sx={{
                    minWidth: '100%',
                    height: 'auto'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -20,
                    right: '25%'
                  }}
                >
                  click to edit
                </Box>
              </>
            ) : (
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: 2
                }}
              >
                <Icon
                  color="black"
                  icon="pencil"
                  width={15}
                  height={15}
                />
                Add Signature
              </Flex>
            )}
          </Box>
        )}
      >
        {({ toggle }) => {
          return (
            <ModalBody>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid',
                  borderColor: 'text',
                  borderRadius: '5px',
                  bg: 'white'
                }}
              >
                <SignaturePad
                  ref={signaturePadRef}
                  onEnd={handleEnd}
                />
              </Box>
              <ModalFooter>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={onClear}
                >
                  Clear
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    handleEnd()
                    toggle()
                  }}
                >
                  {signature
                    ? 'Update Signature'
                    : 'Accept Signature'}
                </Button>
              </ModalFooter>
            </ModalBody>
          )
        }}
      </ModalButton>
    </>
  )
}

export default SignatureInputModal
