/** @jsxImportSource theme-ui */
import { Fragment, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import useForm from 'src/app/hooks/useForms'
import { useToast } from 'src/components/toasts'
import { Flex, Box } from 'theme-ui'
import ModalButton from 'src/app/components/Shared/ModalButton'

import Button from 'src/app/components/Shared/Elements/Button'
import Input from 'src/app/components/Shared/Elements/Input'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Form from 'src/app/components/Shared/Elements/Form'
import ModalFooter from 'src/app/components/Shared/Elements/ModalFooter'
import Switch from 'src/app/components/Shared/Elements/Switch'
import Label from 'src/app/components/Shared/Elements/Label'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'

import {
  CREATE_STATUS_TEMPLATE,
  UPDATE_STATUS_TEMPLATE
} from 'src/app/hooks/mutations'

import { fieldItemConfig } from 'src/app/components/Operations/Forms/formBuilder/dataMapping'
import capitalize from 'lodash/capitalize'
import uniqBy from 'lodash/uniqBy'
import FormError, {
  cleanGraphQLError
} from 'src/app/components/Forms/FormError'

import HexColorInputModal from 'src/app/components/Shared/HexColorInputModal'

import CustomSelectInput from '../Forms/CustomSelectInput'
import { cloneDeep } from '@apollo/client/utilities'
import {
  GET_AGENCY_CURRENT_STATUS_VALUES,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'
import { RolesOnly } from 'src/app/services/auth'

import UserContactSelect from '../Shared/UserContactSelect'
import {
  checkContactInfoHasValue,
  handleContactInfosSet
} from 'src/utils/handleContactInfosSet'
import { UpdatedByTooltipContainer } from './StatusTemplateAutomationTooltip'
import { useConfirm } from 'src/app/hooks/useConfirm'
import Badge from '../Shared/Elements/Badge'
import SyncFormQuestionAndStatus from './SyncFormQuestionsAndStatus'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import ListGroup from '../Shared/Elements/ListGroup'
import Spinner from 'src/images/icons/Spinner'
import { STATUS_TEMPLATE_FRAGMENT } from 'src/app/hooks/fragments'
import { GET_STATUS_TEMPLATES } from 'src/app/hooks/queries'
import { RecurringFrequencySelectInputs } from '../Forms/RecurringFrequencySelectInputs'
import {
  generateRecurringFrequencyDescription,
  mapRecurringFrequencyToState
} from '../Forms/RecurringFrequencySelectInputs/utils'
import InlineTooltip from '../Shared/InlineTooltip'
import { theme } from 'src/styles'

const ALERT_LEVELS = ['NEEDS_ATTENTION', 'CRITICAL']
const EXP_ALERT_LEVELS = ['RESET', 'NEEDS_ATTENTION', 'CRITICAL']

function getAlertLevelOptions(expirationStatus) {
  return expirationStatus ? EXP_ALERT_LEVELS : ALERT_LEVELS
}

const ALERT_LEVEL_DEFAULTS = {
  BOOLEAN: {
    alertLevel: 'CRITICAL',
    operator: 'equals',
    value: 'false'
  },

  DATETIME: {
    alertLevel: 'CRITICAL',
    operator: 'greater_than',
    value: '1'
  },
  EXPIRATION: {
    alertLevel: 'CRITICAL',
    operator: 'greater_than',
    value: '1',
    isExpirationRule: true
  }
}

const GET_STATUS_TEMPLATE = gql`
  query StatusTemplate($id: String!) {
    myAgenciesStatusTemplates(where: { id: { equals: $id } }) {
      ...StatusTemplateFragment
      updatedBy(where: { active: { equals: true } }) {
        valueFromFormQuestion {
          id
          helpText
          formField {
            id
            title
            form {
              id
              name
            }
          }
        }
      }
    }
  }
  ${STATUS_TEMPLATE_FRAGMENT}
`

export const EditStatusTemplateModalButton = ({
  id,
  buttonLabel,
  statusTemplateCategories,
  buttonProps,
  statusTemplateTitle
}) => {
  return (
    <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
      <ModalButton
        modalProps={{ checkOnClose: true }}
        buttonLabel={buttonLabel}
        buttonProps={{
          variant: 'naked',
          icon: 'gear',
          iconColor: 'text',
          ...buttonProps
        }}
        modalHeader={`Update ${capitalize(
          statusTemplateTitle || 'Status'
        )}`}
      >
        {({ toggle }) => (
          <EditStatusTemplateFormContainer
            templateId={id}
            statusTemplateCategories={statusTemplateCategories}
            onFinish={toggle}
          />
        )}
      </ModalButton>
    </RolesOnly>
  )
}

const EditStatusTemplateFormContainer = ({
  templateId,
  statusTemplateCategories,
  onFinish
}) => {
  const { data } = useQuery(GET_STATUS_TEMPLATE, {
    variables: {
      id: templateId
    }
  })

  const statusTemplate = data?.myAgenciesStatusTemplates?.[0]

  if (!statusTemplate) return <Spinner />

  return (
    <StatusTemplateForm
      nodeType={statusTemplate?.statusOnNodeType}
      statusTemplate={statusTemplate}
      onFinish={onFinish}
      categories={statusTemplateCategories}
    />
  )
}

const NewStatusTemplateModalButton = ({
  nodeType,
  buttonLabel,
  statusTemplateCategories,
  buttonProps
}) => {
  return (
    <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
      <ModalButton
        modalProps={{ checkOnClose: true }}
        buttonLabel={
          buttonLabel || `New ${capitalize(nodeType)} Status`
        }
        buttonProps={{
          variant: 'primary',
          icon: 'plus',
          ...buttonProps
        }}
        modalHeader={`New ${capitalize(nodeType)} Status`}
      >
        {({ toggle }) => (
          <StatusTemplateForm
            nodeType={nodeType}
            onFinish={toggle}
            categories={statusTemplateCategories}
          />
        )}
      </ModalButton>
    </RolesOnly>
  )
}

export const StatusTemplateForm = ({
  statusTemplate,
  categories,
  type,
  title,
  defaultAllowedValues,
  nodeType,
  onFinish,
  onSuccess
}) => {
  const { isConfirmed } = useConfirm()
  const { currentAgency } = useGetCurrentAgencyContext()
  const { add } = useToast()
  const typeOptions = fieldItemConfig.filter(fi => fi.canBeStatus)
  const nodeOptions = fieldItemConfig.filter(fi => fi.isNodeType)

  const defaultStatusNodeType =
    nodeType &&
    (nodeOptions?.find(to => nodeType === to.value)?.pulseValue ||
      nodeType?.toUpperCase())

  const defaultType = type
    ? typeOptions?.find(to => type === to.value)?.pulseValue
    : typeOptions[0].value.toUpperCase()

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    mergeValues,
    handleErrors,
    handleWarnings,
    warnings,
    errors
  } = useForm(
    statusTemplate ? onUpdate : onCreate,
    statusTemplate
      ? {
          ...statusTemplate,
          alertLevelRules: statusTemplate?.alertLevelRules?.filter(
            alr => !alr?.isExpirationRule
          ),
          expAlertLevelRules: statusTemplate?.alertLevelRules?.filter(
            alr => alr?.isExpirationRule
          )
        }
      : {
          title: title,
          type: defaultType,
          statusOnNodeType: defaultStatusNodeType,
          allowedValues:
            defaultAllowedValues?.map(alv => ({ value: alv })) || [],
          expAlertLevelRules: [],
          alertLevelRules: ALERT_LEVEL_DEFAULTS[defaultType]
            ? [ALERT_LEVEL_DEFAULTS[defaultType]]
            : []
        },
    {
      required: ['title']
    }
  )

  const [updateStatusTemplate, { loading: updating }] = useMutation(
    UPDATE_STATUS_TEMPLATE,

    {
      refetchQueries: [
        'MyAgenciesCurrentStatuses',
        'GetStatusValue',
        'StatusTemplatesCategories'
      ],
      update: (cache, { data: d }) => {
        if (!d?.updateStatusTemplate) return

        const data = cloneDeep(
          cache.readQuery({
            query: GET_STATUS_TEMPLATES
          })
        )

        if (data?.myAgenciesStatusTemplates) {
          data.myAgenciesStatusTemplates.map(st =>
            st.id === d?.updateStatusTemplate.id
              ? d?.updateStatusTemplate
              : st
          )

          if (d?.updateStatusTemplate?.category) {
            data.myAgenciesStatusTemplateCategories = uniqBy(
              (data.myAgenciesStatusTemplateCategories || []).concat(
                d?.updateStatusTemplate?.category
              ),
              'id'
            )
          }

          cache.writeQuery({
            query: GET_STATUS_TEMPLATES,
            data: {
              ...data
            }
          })
        }
      }
    }
  )

  const [createStatusTemplate, { loading: creating }] = useMutation(
    CREATE_STATUS_TEMPLATE,
    {
      refetchQueries: [
        'StatusTemplatesCategories',
        'StatusTemplates'
      ],
      update: (cache, { data: { createStatusTemplate } }) => {
        const data = cloneDeep(
          cache.readQuery({
            query: GET_STATUS_TEMPLATES
          })
        )

        let statusData = cloneDeep(
          cache.readQuery({
            query: GET_AGENCY_CURRENT_STATUS_VALUES,
            variables: {
              statusOnNodeType: createStatusTemplate.statusOnNodeType
            }
          })
        )

        const newStatusTemp = {
          ...createStatusTemplate,
          statusValues: [],
          updatedBy: []
        }

        if (data?.myAgenciesStatusTemplates) {
          data.myAgenciesStatusTemplates.push(newStatusTemp)

          if (createStatusTemplate?.category) {
            data.myAgenciesStatusTemplateCategories = uniqBy(
              (data.myAgenciesStatusTemplateCategories || []).concat(
                createStatusTemplate.category
              ),
              'id'
            )
          }

          cache.writeQuery({
            query: GET_STATUS_TEMPLATES,
            data: {
              ...data
            }
          })

          const newStatusData = statusData?.myAgenciesCurrentStatuses
            ? [...statusData.myAgenciesCurrentStatuses, newStatusTemp]
            : [newStatusTemp]

          cache.writeQuery({
            query: GET_AGENCY_CURRENT_STATUS_VALUES,
            variables: {
              statusOnNodeType: createStatusTemplate.statusOnNodeType
            },
            data: {
              myAgenciesCurrentStatuses: newStatusData
            }
          })
        }
      }
    }
  )
  const loading = creating || updating
  const currentTypeConfig = typeOptions?.find(
    to => values?.type === to.pulseValue
  )

  async function onCreate() {
    const res = await createStatusTemplate({
      variables: {
        data: {
          title: values.title,
          type: values.type.toUpperCase(),
          statusOnNodeType: values?.statusOnNodeType,
          isPrimary: values.isPrimary,
          runningTotal: values.runningTotal,
          valueTypeFormat: values.valueTypeFormat,
          onlyForGroups: values?.onlyForGroups?.map(ofg => ofg.id),
          allowedValues: values.allowedValues
            ?.filter(av => !av.deleted && av?.value?.length > 0)
            .map(({ contactInfos, ...av }) => ({
              ...handleContactInfosSet(contactInfos),
              ...av
            })),
          alertLevelRules: [
            ...(values.alertLevelRules || []),
            ...(values.expAlertLevelRules || [])
          ]
            ?.filter(alv => !alv.deleted && alv.value)
            ?.map(({ contactInfos, ...ar }) => ({
              ...ar,
              ...handleContactInfosSet(contactInfos),
              units: ar.isExpirationRule
                ? ar.units || 'days'
                : values.type === 'DATETIME'
                ? 'days'
                : null
            })),
          categoryId: !values?.category?.__isNew__
            ? values?.category?.id
            : null,
          categoryName: values?.category?.__isNew__
            ? values?.category?.name
            : null
        }
      }
    })

    if (!res.errors) {
      add({
        color: 'success',
        content: `${values.title.trim()} template created`
      })
      onSuccess && onSuccess(res?.data?.createStatusTemplate)
    } else {
      add({
        color: 'danger',
        content: cleanGraphQLError(res.errors[0])
      })
    }
    onFinish && onFinish()
  }

  async function onUpdate() {
    const res = await updateStatusTemplate({
      variables: {
        id: statusTemplate.id,
        data: {
          title: values.title,
          isPrimary: values.isPrimary,
          runningTotal: values.runningTotal,
          valueTypeFormat: values.valueTypeFormat,
          onlyForGroups:
            values?.onlyForGroups?.map(ofg => ofg.id) || [],
          allowedValues: values.allowedValues
            ?.filter(av => av?.value?.length > 0 || av?.id)
            .map(({ contactInfos, ...av }) => ({
              id: av.id,
              value: av.value.trim(),
              color: av.color,
              alertLevel: av.alertLevel,
              deleted: av.deleted,
              ...handleContactInfosSet(contactInfos)
            })),
          alertLevelRules: [
            ...(values.alertLevelRules || []),
            ...(values.expAlertLevelRules || [])
          ]?.map(
            ({
              __typename,
              contactInfos,

              ...ar
            }) => {
              return {
                ...ar,
                deleted: ar.deleted,
                units: ar.isExpirationRule
                  ? ar.units || 'days'
                  : values.type === 'DATETIME'
                  ? 'days'
                  : null,
                ...handleContactInfosSet(contactInfos)
              }
            }
          ),
          categoryId: !values?.category?.__isNew__
            ? values?.category?.id
            : null,
          categoryName: values?.category?.__isNew__
            ? values?.category?.name
            : null
        }
      }
    })

    if (!res.errors) {
      add({
        color: 'success',
        content: `${values.title.trim()} template updated`
      })

      if (values.allowedValues !== statusTemplate?.allowedValues) {
        confirm = await isConfirmed(
          <Box sx={{ width: '100%' }}>
            <h5>
              Would you like to sync the new restricted options to the
              form questions connected?
            </h5>
            <Box
              sx={{
                mt: '30px',
                width: '100%'
              }}
            >
              <h6>Connected Questions</h6>
              <ListGroup>
                {res?.data?.updateStatusTemplate?.updatedBy?.map(
                  (u, i) => (
                    <ListGroupItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '5px',
                        width: '100%'
                      }}
                      key={i}
                    >
                      <Flex sx={{ alignItems: 'center', gap: '5px' }}>
                        {
                          u?.valueFromFormQuestion?.formField?.form
                            ?.name
                        }
                        {!u?.active && <Badge>Inactive</Badge>}
                      </Flex>
                      <SyncFormQuestionAndStatus
                        fieldItemId={u?.valueFromFormQuestion?.id}
                        statusTemplateId={statusTemplate.id}
                      />
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            </Box>
          </Box>,
          'Done',
          null
        )
      }

      onSuccess && onSuccess(res?.data?.createStatusTemplate)
    } else {
      add({
        color: 'danger',
        content: cleanGraphQLError(res.errors[0])
      })
    }
    onFinish && onFinish()
  }

  function handleNestedValueChange(objectKey, key, valueKey, value) {
    let otherChanges = {}
    if (valueKey === 'operator') {
      otherChanges = {
        value: null,
        units: null
      }
    }

    mergeValues({
      [objectKey]: values[objectKey].map((a, k) =>
        k === key
          ? {
              ...a,
              ...otherChanges,
              [valueKey]: value && value
            }
          : a
      )
    })
  }

  function removeNestedValue(objectKey, key, clearAll) {
    const newList = values[objectKey]?.map((v, k) => {
      return { ...v, deleted: k === key ? !v.deleted : v.deleted }
    })

    setValues({
      ...values,
      [objectKey]: newList.length > 0 ? newList : clearAll ? [] : [{}]
    })
  }

  function addNestedValue(objectKey, defaultValue = {}) {
    setValues({
      ...values,
      [objectKey]: [...values[objectKey], defaultValue]
    })
  }

  const allowedValues = values.allowedValues

  const alertLevelRules = values.alertLevelRules
  const expAlertLevelRules = values.expAlertLevelRules

  const notReady = [
    ...values?.alertLevelRules,
    ...values?.expAlertLevelRules
  ]?.some(alr => !alr?.alertLevel || !alr?.value || !alr?.operator)

  return (
    <Form onSubmit={handleSubmit} inline>
      <FormGroup error={errors['title']}>
        <Label>Title</Label>
        <Input
          id="title"
          name="title"
          required
          value={values.title || ''}
          onChange={handleChange}
          placeholder="Status Title"
        />
      </FormGroup>

      {!statusTemplate && (
        <FormGroup>
          <Label>Data Type</Label>
          <Input
            disabled={type}
            onChange={e => {
              setValues({
                ...values,
                allowedValues: [],
                valueTypeFormat: null,
                alertLevelRules: ALERT_LEVEL_DEFAULTS[e.target.value]
                  ? [ALERT_LEVEL_DEFAULTS[e.target.value]]
                  : []
              })
              handleChange(e)
            }}
            type="select"
            name="type"
            id="type"
            value={values.type}
          >
            {typeOptions.map(r => (
              <option value={r.pulseValue}>{r.title}</option>
            ))}
          </Input>
        </FormGroup>
      )}
      {values.type === 'NUMBER' && (
        <FormGroup>
          <Switch
            id={`runningTotal`}
            name="runningTotal"
            label={'Show Running Total'}
            checked={values.runningTotal}
            readOnly
            onClick={handleChange}
          />
        </FormGroup>
      )}
      {values.type === 'NUMBER' && (
        <FormGroup
          sx={{
            mb: '40px'
          }}
        >
          <Label>Custom Format</Label>
          <Input
            onChange={e => {
              handleChange(e)
            }}
            type="select"
            name="valueTypeFormat"
            id="valueTypeFormat"
            value={values.valueTypeFormat}
          >
            {...[
              <option selected disabled value={null}>
                Select Custom Format
              </option>,
              ...currentTypeConfig?.valueTypeFormats?.map(r => (
                <option value={r}>{capitalize(r)}</option>
              ))
            ]}
          </Input>
          {values.valueTypeFormat === 'duration' && (
            <InlineTooltip
              sx={{
                mt: '20px'
              }}
              bg={'warning'}
              tip={
                'You have chosen duration formatting.  Restricted options and alert rules can be written as total seconds.'
              }
            />
          )}
        </FormGroup>
      )}

      {(values.type === 'TEXT' || values.type === 'NUMBER') && (
        <AllowedValuesList
          allowedValues={allowedValues}
          handleNestedValueChange={handleNestedValueChange}
          addNestedValue={addNestedValue}
          removeNestedValue={removeNestedValue}
          handleWarnings={handleWarnings}
          warnings={warnings}
          type={values.type}
          nodeType={statusTemplate?.statusOnNodeType}
          placeholder={
            values.valueTypeFormat === 'duration'
              ? 'Total Seconds'
              : ''
          }
        />
      )}

      {values.type !== 'TEXT' && (
        <AlertRulesList
          handleNestedValueChange={handleNestedValueChange}
          addNestedValue={addNestedValue}
          removeNestedValue={removeNestedValue}
          type={values.type}
          handleErrors={handleErrors}
          errors={errors}
          handleWarnings={handleWarnings}
          warnings={warnings}
          alertLevelRules={alertLevelRules}
          nodeType={statusTemplate?.statusOnNodeType}
          placeholder={
            values.valueTypeFormat === 'duration'
              ? 'Total Seconds'
              : ''
          }
        />
      )}

      <ExpirationRulesList
        handleNestedValueChange={handleNestedValueChange}
        addNestedValue={addNestedValue}
        removeNestedValue={removeNestedValue}
        type={values.type}
        handleErrors={handleErrors}
        errors={errors}
        handleWarnings={handleWarnings}
        warnings={warnings}
        alertLevelRules={expAlertLevelRules}
        nodeType={statusTemplate?.statusOnNodeType}
      />
      <FormGroup>
        <Label>Status Set</Label>
        <CustomSelectInput
          id="set"
          placeholder="Select a status group or create new one"
          getOptionValue={v => v.id || undefined}
          getOptionLabel={v => v.name || undefined}
          defaultValue={[]}
          value={values.category || []}
          formatCreateLabel={val => `Create status set: ${val}`}
          isValidNewOption={(inputVal, val, options) => {
            return (
              inputVal && !options.find(opt => opt.name === inputVal)
            )
          }}
          creatable
          getNewOptionData={(inputValue, optionLabel) => {
            return {
              id: inputValue,
              name: optionLabel,
              __isNew__: true
            }
          }}
          isClearable
          onChange={selected => {
            setValues({
              ...values,
              category: selected
            })
          }}
          options={categories || []}
        />
      </FormGroup>
      <FormGroup>
        <Label>{`Only show this status for ${capitalize(
          nodeType
        )}s in the following groups`}</Label>
        <CustomSelectInput
          id="onlyForGroups"
          name="onlyForGroups"
          isMulti={true}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          isClearable={false}
          withColor
          value={values.onlyForGroups}
          onChange={(selected = []) => {
            setValues(v => ({
              ...v,
              onlyForGroups: selected
            }))
          }}
          options={currentAgency.positionCategories}
        />
      </FormGroup>
      <FormGroup>
        <Switch
          id={`isPrimary`}
          name="isPrimary"
          label={'Pin this status'}
          checked={values.isPrimary}
          readOnly
          onClick={handleChange}
        />
      </FormGroup>

      <ModalFooter
        alertBadgeVariant="secondaryReverse"
        alertBadge={
          statusTemplate?.id && (
            <Flex
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              Status has{' '}
              <UpdatedByTooltipContainer
                statusTemplateId={statusTemplate?.id}
              />
              forms connected
            </Flex>
          )
        }
        onClose={onFinish}
      >
        <Button
          type="submit"
          disabled={loading || notReady}
          icon={loading && 'spinner'}
        >
          {statusTemplate ? 'Update' : 'Create Status Definition'}
        </Button>
      </ModalFooter>
    </Form>
  )
}

const BooleanAlertCondition = ({
  handleNestedValueChange,
  alertLevelRule,
  index
}) => {
  return (
    <>
      If answer is
      <Box sx={{ width: '100px' }}>
        <Input
          onChange={e =>
            handleNestedValueChange(
              'alertLevelRules',
              index,
              'value',
              e.target.value
            )
          }
          type="select"
          name="value"
          id="value"
          value={alertLevelRule.value}
        >
          {['true', 'false'].map(r => (
            <option value={r}>{r === 'true' ? 'Yes' : 'No'}</option>
          ))}
        </Input>
      </Box>
      Status alert level is
      <Input
        onChange={e => {
          handleNestedValueChange(
            'alertLevelRules',
            index,
            'alertLevel',
            e.target.value
          )
        }}
        type="select"
        name="alertLevel"
        id="alertLevel"
        value={alertLevelRule.alertLevel}
      >
        {ALERT_LEVELS.map(r => (
          <option value={r}>{capitalize(r.replace('_', ' '))}</option>
        ))}
      </Input>
    </>
  )
}

const DatetimeAlertCondition = ({
  alertLevelRule,
  handleNestedValueChange,
  index,
  handleErrors,
  passedByOnly,
  units,
  fieldKey = 'alertLevelRules',
  customValueText,
  isExpirationRule
}) => {
  const fixed = alertLevelRule.operator === 'exp_recurring'

  return (
    <Flex
      sx={{
        gap: '5px',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      Mark as
      <Input
        sx={{ minWidth: '130px', fontSize: 1 }}
        onChange={e => {
          handleNestedValueChange(
            fieldKey,
            index,
            'alertLevel',
            e.target.value
          )
        }}
        type="select"
        name="alertLevel"
        id="alertLevel"
        value={alertLevelRule.alertLevel}
      >
        {getAlertLevelOptions(isExpirationRule).map(r => (
          <option value={r}>{capitalize(r.replace('_', ' '))}</option>
        ))}
      </Input>
      <>
        {customValueText}
        {passedByOnly ? (
          <Input
            sx={{
              minWidth: '120px',
              fontSize: 1
            }}
            onChange={e => {
              handleNestedValueChange(
                fieldKey,
                index,
                'operator',
                e.target.value
              )
            }}
            type="select"
            name="operator"
            id="operator"
            value={alertLevelRule.operator}
          >
            {[
              {
                label: 'after',
                value: 'greater_than'
              },
              {
                label: 'at a set time',
                value: 'exp_recurring'
              }
            ].map(r => (
              <option value={r.value}>{r.label}</option>
            ))}
          </Input>
        ) : (
          <Input
            sx={{ minWidth: '120px', fontSize: 1 }}
            onChange={e =>
              handleNestedValueChange(
                fieldKey,
                index,
                'operator',
                e.target.value
              )
            }
            type="select"
            name="operator"
            id="operator"
            value={alertLevelRule.operator}
          >
            {[
              {
                label: 'passed by',
                value: 'greater_than'
              },
              {
                label: 'coming up in',
                value: 'less_than'
              }
            ].map(r => (
              <option value={r.value}>{r.label}</option>
            ))}
          </Input>
        )}
        {fixed ? (
          <AlertLevelRuleRecurringDateModalButton
            value={alertLevelRule.value}
            setValue={v => {
              handleNestedValueChange(fieldKey, index, 'value', v)
            }}
          />
        ) : (
          <>
            <Input
              sx={{ maxWidth: '60px' }}
              onChange={e => {
                handleErrors(
                  e,
                  e.target.value?.length > 0,
                  'Must Provide amount of days.'
                )
                handleNestedValueChange(
                  fieldKey,
                  index,
                  'value',
                  e.target.value
                )
              }}
              name={`alertLevelRules${index}.value`}
              id={`alertLevelRules${index}.value`}
              value={alertLevelRule.value || ''}
              type="number"
            />
            {units?.length > 0 ? (
              <Input
                sx={{ minWidth: '100px' }}
                onChange={e =>
                  handleNestedValueChange(
                    fieldKey,
                    index,
                    'units',
                    e.target.value
                  )
                }
                type="select"
                name="units"
                id="units"
                value={alertLevelRule.units}
              >
                {[
                  {
                    label: 'day',
                    value: 'day'
                  },
                  {
                    label: 'hour',
                    value: 'hour'
                  }
                ].map(r => (
                  <option value={r.value}>{r.label}</option>
                ))}
              </Input>
            ) : parseInt(alertLevelRule.value) > 1 ? (
              'days'
            ) : (
              'day'
            )}
          </>
        )}
      </>
    </Flex>
  )
}

const AlertLevelRuleRecurringDateModalButton = ({
  value,
  setValue
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const parsedValue = value && JSON.parse(value)

  return (
    <ModalButton
      buttonLabel={
        parsedValue
          ? generateRecurringFrequencyDescription({
              ...parsedValue
            })
          : 'Set Recurring Date'
      }
      buttonProps={{
        variant: parsedValue?.frequency ? 'primary' : 'secondary',
        style: {
          fontSize: 10
        }
      }}
      modalHeader={'Recurring Date' + ` (${currentAgency?.timezone})`}
    >
      {({ toggle }) => {
        return (
          <AlertLevelRuleRecurringDateForm
            value={parsedValue}
            setValue={v => {
              if (!v) return
              setValue(JSON.stringify(v))
              toggle()
            }}
          />
        )
      }}
    </ModalButton>
  )
}

const AlertLevelRuleRecurringDateForm = ({
  value = '',
  setValue
}) => {
  const [newValues, setNewValues] = useState(
    value ? mapRecurringFrequencyToState(value) : {}
  )

  return (
    <>
      <RecurringFrequencySelectInputs
        values={newValues}
        setValues={setNewValues}
      />
      <ModalFooter>
        <Button
          disabled={
            !newValues?.frequency ||
            newValues?.frequency?.required?.some(r => !newValues[r])
          }
          onClick={() =>
            setValue({
              hour: newValues?.hour?.value,
              minute: newValues?.minute?.value,
              frequency: newValues?.frequency?.value,
              dayOfWeek: newValues?.dayOfWeek?.value,
              dayOfMonth:
                typeof newValues?.dayOfMonth?.value === 'number'
                  ? newValues?.dayOfMonth?.value
                  : newValues?.dayOfMonth?.value(
                      newValues?.monthOfYear?.daysInMonth
                    ),
              monthOfYear: newValues?.monthOfYear?.value
            })
          }
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

const NumberAlertCondition = ({
  alertLevelRule,
  handleNestedValueChange,
  index,
  placeholder,
  handleErrors
}) => {
  return (
    <Flex
      sx={{
        gap: '5px',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      Mark
      <Input
        sx={{ maxWidth: '70px' }}
        onChange={e => {
          handleNestedValueChange(
            'alertLevelRules',
            index,
            'alertLevel',
            e.target.value
          )
        }}
        type="select"
        name="alertLevel"
        id="alertLevel"
        value={alertLevelRule.alertLevel || null}
      >
        {[
          <option value={null} selected disabled>
            select
          </option>,
          ...['NEEDS_ATTENTION', 'CRITICAL'].map(r => (
            <option value={r}>
              {capitalize(r.replace('_', ' '))}
            </option>
          ))
        ]}
      </Input>
      when value is
      <Input
        onChange={e =>
          handleNestedValueChange(
            'alertLevelRules',
            index,
            'operator',
            e.target.value
          )
        }
        type="select"
        name="operator"
        id="operator"
        value={alertLevelRule.operator || null}
      >
        {[
          <option value={null} selected disabled>
            select
          </option>,
          ...[
            {
              label: 'greater than',
              value: 'greater_than'
            },
            {
              label: 'greater than equal to',
              value: 'greater_than_equal_to'
            },
            {
              label: 'less than',
              value: 'less_than'
            },
            {
              label: 'less than equal to',
              value: 'less_than_equal_to'
            }
          ].map(r => <option value={r.value}>{r.label}</option>)
        ]}
      </Input>
      <Input
        sx={{ maxWidth: '100px' }}
        onChange={e => {
          handleErrors(
            e,
            e.target.value?.length > 0,
            'Must Provide amount.'
          )
          handleNestedValueChange(
            'alertLevelRules',
            index,
            'value',
            e.target.value
          )
        }}
        placeholder={placeholder || 'Enter a value'}
        name={`alertLevelRules${index}.value`}
        id={`alertLevelRules${index}.value`}
        value={alertLevelRule.value}
        type="number"
      />
    </Flex>
  )
}

const AlertRulesList = ({
  alertLevelRules,
  errors,
  type,
  nodeType,
  addNestedValue,
  handleNestedValueChange,
  removeNestedValue,
  handleErrors,
  warnings,
  handleWarnings,
  placeholder
}) => {
  return (
    <Box sx={{ bg: 'gray100', p: '20px', mb: '15px', mt: '-25px' }}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          borderBottom: 'default',
          mb: '15px',
          pb: 10,
          alignItems: 'center'
        }}
      >
        <h6 sx={{ mb: '0px' }}>Alert Rules</h6>{' '}
        <Button
          sx={{ p: '0px' }}
          variant="link"
          onClick={() => {
            addNestedValue(
              'alertLevelRules',
              ALERT_LEVEL_DEFAULTS[type] || {}
            )
          }}
        >
          Add Rule +
        </Button>
      </Flex>
      {alertLevelRules?.map((alr, k) =>
        alr.deleted ? (
          <Fragment />
        ) : (
          <>
            <Flex
              sx={{
                gap: '10px',
                alignItems: 'center',
                mb: '5px',
                flexWrap: 'wrap'
              }}
            >
              {type === 'NUMBER' && (
                <NumberAlertCondition
                  alertLevelRule={alr}
                  handleNestedValueChange={handleNestedValueChange}
                  index={k}
                  handleErrors={handleErrors}
                  placeholder={placeholder || 'Enter a value'}
                />
              )}
              {type === 'BOOLEAN' && (
                <BooleanAlertCondition
                  alertLevelRule={alr}
                  index={k}
                  handleNestedValueChange={handleNestedValueChange}
                />
              )}
              {type === 'DATETIME' && (
                <DatetimeAlertCondition
                  alertLevelRule={alr}
                  handleNestedValueChange={handleNestedValueChange}
                  index={k}
                  handleErrors={handleErrors}
                />
              )}

              {alr?.alertLevel && (
                <EditAlertContactInfoModalButton
                  value={alr.contactInfos || []}
                  alertOwner={alr.alertOwner}
                  warnings={warnings}
                  handleWarnings={handleWarnings}
                  alertOwnerAllowed={nodeType === 'USER'}
                  handleChange={e => {
                    handleNestedValueChange(
                      'alertLevelRules',
                      k,
                      e?.target?.name || e?.name || 'contactInfos',
                      e.target?.value || e?.value
                    )
                  }}
                />
              )}
              <Button
                variant="secondary"
                onClick={() => {
                  removeNestedValue('alertLevelRules', k)
                }}
                icon="close"
              />
            </Flex>

            <FormError
              customError={errors[`alertLevelRules[${k}].value`]}
            />
          </>
        )
      )}
    </Box>
  )
}

const ExpirationRulesList = ({
  alertLevelRules,
  errors,
  nodeType,
  addNestedValue,
  handleNestedValueChange,
  removeNestedValue,
  handleErrors,
  warnings,
  handleWarnings
}) => {
  return (
    <Box sx={{ bg: 'gray100', p: '20px', mb: '15px', mt: '-25px' }}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          borderBottom: 'default',
          mb: '15px',
          pb: 10,
          alignItems: 'center'
        }}
      >
        <h6 sx={{ mb: '0px' }}>Time Compliance Rules</h6>{' '}
        <Button
          sx={{ p: '0px' }}
          variant="link"
          onClick={() => {
            addNestedValue(
              'expAlertLevelRules',
              ALERT_LEVEL_DEFAULTS['EXPIRATION'] || {}
            )
          }}
        >
          Add Rule +
        </Button>
      </Flex>
      {alertLevelRules?.map((alr, k) =>
        alr.deleted ? (
          <Fragment />
        ) : (
          <>
            <Flex
              sx={{
                gap: '10px',
                alignItems: 'center',
                mb: '5px',
                flexWrap: 'wrap'
              }}
            >
              <DatetimeAlertCondition
                alertLevelRule={alr}
                handleNestedValueChange={handleNestedValueChange}
                index={k}
                handleErrors={handleErrors}
                fieldKey="expAlertLevelRules"
                passedByOnly
                units={['days', 'hours']}
                isExpirationRule
              />

              {alr?.alertLevel && (
                <EditAlertContactInfoModalButton
                  value={alr.contactInfos}
                  alertOwner={alr.alertOwner}
                  warnings={warnings}
                  handleWarnings={handleWarnings}
                  alertOwnerAllowed={nodeType === 'USER'}
                  handleChange={e => {
                    handleNestedValueChange(
                      'expAlertLevelRules',
                      k,
                      e?.target?.name || e?.name || 'contactInfos',
                      e.target?.value || e?.value
                    )
                  }}
                />
              )}
              <Button
                variant="secondary"
                onClick={() => {
                  removeNestedValue('expAlertLevelRules', k)
                }}
                icon="close"
              />
            </Flex>

            <FormError
              customError={errors[`expAlertLevelRules[${k}].value`]}
            />
          </>
        )
      )}
    </Box>
  )
}

const AllowedValuesList = ({
  allowedValues,
  handleNestedValueChange,
  type,
  removeNestedValue,
  handleWarnings,
  warnings,
  addNestedValue,
  placeholder,
  nodeType
}) => {
  return (
    <Box sx={{ bg: 'gray100', p: '20px', mb: '15px', mt: '-25px' }}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          borderBottom: 'default',
          pb: 10,
          alignItems: 'center'
        }}
      >
        <h6 sx={{ mb: '0px' }}>Restricted Options</h6>{' '}
        <Button
          variant="link"
          sx={{ p: '0px' }}
          onClick={() => addNestedValue('allowedValues')}
        >
          Add Option +
        </Button>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          mb: '10px'
        }}
      >
        {allowedValues?.map((av, key) => {
          const allowedValueId = key + '_' + av.id + '_text'
          if (av.deleted) return <Fragment />
          return (
            <Flex
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
                mb: '3px'
              }}
            >
              <Box sx={{ width: '40%' }}>
                <Input
                  onChange={e =>
                    handleNestedValueChange(
                      'allowedValues',
                      key,
                      'value',
                      e.target.value
                    )
                  }
                  placeholder={placeholder || 'Enter a value'}
                  type={type === 'TEXT' ? 'text' : 'number'}
                  name={allowedValueId}
                  id={allowedValueId}
                  value={av.value || ''}
                />
              </Box>
              <HexColorInputModal
                handleSelect={({ value }) =>
                  handleNestedValueChange(
                    'allowedValues',
                    key,
                    'color',
                    value
                  )
                }
                closeOnSelect
                name={allowedValueId}
                id={allowedValueId}
                color={
                  av.color || getColorByAlertLevel(av.alertLevel)
                }
              />

              <Input
                onChange={e =>
                  handleNestedValueChange(
                    'allowedValues',
                    key,
                    'alertLevel',
                    e.target.value || null
                  )
                }
                type={'select'}
                name={allowedValueId}
                id={allowedValueId}
                value={av.alertLevel || ''}
              >
                {[<option value={''}>Ok</option>].concat(
                  ALERT_LEVELS.map(r => (
                    <option value={r}>
                      {capitalize(r.replace('_', ' '))}
                    </option>
                  ))
                )}
              </Input>

              {av.value && (
                <Flex sx={{ gap: '5px' }}>
                  {av?.alertLevel && (
                    <EditAlertContactInfoModalButton
                      value={av.contactInfos}
                      alertOwner={av.alertOwner}
                      warnings={warnings}
                      handleWarnings={handleWarnings}
                      alertOwnerAllowed={nodeType === 'USER'}
                      handleChange={e => {
                        handleNestedValueChange(
                          'allowedValues',
                          key,
                          e?.target?.name ||
                            e?.name ||
                            'contactInfos',
                          e.target?.value || e?.value
                        )
                      }}
                    />
                  )}

                  <Button
                    variant="secondary"
                    onClick={() => {
                      removeNestedValue('allowedValues', key)
                    }}
                    icon="close"
                  />
                </Flex>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Box>
  )
}

const EditAlertContactInfoModalButton = ({
  value,
  alertOwner,
  handleChange,
  warnings,
  alertOwnerAllowed
}) => {
  const fieldKey = 'contactInfo'
  return (
    <ModalButton
      modalHeader={'Email & Text Alerts'}
      buttonProps={{
        icon: 'share',
        variant:
          checkContactInfoHasValue(value) || alertOwner
            ? 'success'
            : 'secondary'
      }}
      renderModalActions={closeModal => {
        return <Button onClick={closeModal}>Done</Button>
      }}
    >
      {() => {
        return (
          <>
            <FormGroup>
              <UserContactSelect
                id={fieldKey}
                name={fieldKey}
                placeholder="Enter an email or phone number. Press enter or click the plus to add multiple."
                value={value}
                errorMessage={warnings[fieldKey]}
                onAdd={curArray => {
                  if (warnings[fieldKey]) {
                    return
                  }

                  handleChange({
                    value: curArray,
                    name: 'contactInfos'
                  })
                }}
              />

              <InputGroupText
                style={{
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  marginBottom: '2rem'
                }}
              >
                Emails and phone numbers entered here will be notified
                when the status reaches falls within the rule.
              </InputGroupText>
            </FormGroup>
            {alertOwnerAllowed && (
              <FormGroup>
                <Input
                  id="alertOwner"
                  name="alertOwner"
                  type="checkbox"
                  value={alertOwner}
                  checked={alertOwner}
                  label="Alert User Affiliated"
                  onChange={e => {
                    handleChange({
                      value: e.target.checked,
                      name: 'alertOwner'
                    })
                  }}
                />
              </FormGroup>
            )}
          </>
        )
      }}
    </ModalButton>
  )
}

function getColorByAlertLevel(alertLevel) {
  return alertLevel === 'CRITICAL'
    ? theme?.colors['danger']
    : alertLevel === 'NEEDS_ATTENTION'
    ? theme?.colors['yellow']
    : theme?.colors['success']
}

export default NewStatusTemplateModalButton
