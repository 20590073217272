import groupBy from 'lodash/groupBy'
import { parseFloatTwoDecimal } from 'src/utils/dataMapping'

function fieldKeyExists(key, obj) {
  return obj.hasOwnProperty(key)
}

export function mapValuesToUpdateFormResponse(
  values,
  agencyId,
  userId,
  draft,
  createdAtChanged
) {
  const groupedByField = groupBy(values.updateFieldItems, 'fieldId')
  const toUpdate = Object.entries(groupedByField).filter(
    ([fieldId]) => !fieldId.startsWith('new')
  )
  const toCreate = Object.entries(
    groupedByField
  ).filter(([fieldId]) => fieldId.startsWith('new'))

  return {
    where: { id: values.id },
    data: {
      weather: values.weather && parseAndMapWeather(values.weather),
      draft: draft || false,
      ...(values.createdAt &&
        createdAtChanged && { createdAt: values.createdAt }),
      fields: {
        ...(toCreate?.length > 0 && {
          create: toCreate.map(([fieldId, fieldItems]) => {
            return {
              formField: {
                connect: { id: fieldId?.replace('new_', '') }
              },
              fieldItems: {
                create: fieldItems.map(fieldItem => {
                  return generateFieldItemResponseCreateOrUpdateArgs(
                    fieldItem,
                    true,
                    agencyId,
                    userId
                  )
                })
              }
            }
          })
        }),
        ...(toUpdate?.length > 0 && {
          update: toUpdate.map(([fieldId, fieldItems]) => {
            return {
              where: {
                id: fieldId
              },
              data: {
                fieldItems: {
                  upsert: fieldItems.map(fieldItem => {
                    return {
                      where: {
                        id: fieldItem.id
                      },
                      create: generateFieldItemResponseCreateOrUpdateArgs(
                        fieldItem,
                        true,
                        agencyId,
                        userId
                      ),
                      update: generateFieldItemResponseCreateOrUpdateArgs(
                        fieldItem,
                        false,
                        agencyId,
                        userId
                      )
                    }
                  })
                }
              }
            }
          })
        })
      }
    }
  }
}

function generateFieldItemResponseCreateOrUpdateArgs(
  fieldItem,
  create,
  agencyId,
  userId
) {
  const {
    id,
    fieldId,
    locations,
    file,
    text,
    number,
    ...rest
  } = fieldItem

  let locationsToRemove
  let locationsToUpsert

  if (locations) {
    locationsToRemove = locations.filter(l => l.removed)
    locationsToUpsert = locations.filter(l => !l.removed)
  }

  let filesToRemove
  let filesToAdd

  if (file) {
    filesToRemove = Array.isArray(file)
      ? file.filter(f => f.removed && f.id)
      : file['removed']

    filesToAdd = Array.isArray(file)
      ? file.filter(f => !f.removed && f.filestackId && !f.id)
      : !file['removed'] && file.filestackId && !file.id
  }

  return {
    ...rest,
    ...(create && {
      formFieldItem: {
        connect: { id: id?.replace('new_', '') }
      }
    }),
    ...(fieldKeyExists('text', fieldItem) &&
      arrayOrSingle(text, 'text', 'multiText')),
    ...(fieldKeyExists('number', fieldItem) &&
      arrayOrSingle(number, 'number', 'multiNumber')),
    ...(fieldKeyExists('assets', fieldItem) &&
      arrayOrSingle(
        fieldItem.assets,
        'assets',
        'assets',
        (val, isArray) => ({
          [create ? 'connect' : 'set']: !val
            ? []
            : isArray
            ? val.map(v => ({ id: v.id }))
            : [{ id: val.id }]
        })
      )),
    ...(fieldKeyExists('positions', fieldItem) &&
      arrayOrSingle(
        fieldItem.positions,
        'positions',
        'positions',
        (val, isArray) => ({
          [create ? 'connect' : 'set']: !val
            ? []
            : isArray
            ? val.map(v => ({ id: v.id }))
            : [{ id: val.id }]
        })
      )),
    ...(fieldKeyExists('users', fieldItem) &&
      arrayOrSingle(
        fieldItem.users,
        'users',
        'users',
        (val, isArray) => ({
          [create ? 'connect' : 'set']: !val
            ? []
            : isArray
            ? val.map(v => ({ id: v.id }))
            : [{ id: val.id }]
        })
      )),
    ...((locationsToRemove || locationsToUpsert) && {
      locations: {
        ...(locationsToUpsert && create
          ? {
              create: locationsToUpsert.map(v =>
                mapLocationToCreate(v)
              )
            }
          : {
              upsert: Array.isArray(locationsToUpsert)
                ? locationsToUpsert.map(v => mapLocationToUpsert(v))
                : mapLocationToUpsert(locationsToUpsert)
            }),
        ...(locationsToRemove &&
          !create && {
            disconnect: Array.isArray(locationsToRemove)
              ? locationsToRemove.map(v => ({
                  id: v.id
                }))
              : { id: locationsToRemove.id }
          })
      }
    }),
    ...((filesToRemove || filesToAdd) && {
      files: {
        ...(filesToAdd && {
          create: Array.isArray(filesToAdd)
            ? filesToAdd.map(v =>
                mapFileToCreate(v, agencyId, userId)
              )
            : mapFileToCreate(filesToAdd, agencyId, userId)
        }),
        ...(filesToRemove &&
          !create && {
            delete: Array.isArray(filesToRemove)
              ? filesToRemove.map(v => ({
                  id: v.id
                }))
              : { id: filesToRemove.id }
          })
      }
    })
  }
}

function mapArgsArrayOrSingleToConnectId(val, isArray) {
  return isArray
    ? val.filter(v => v.id).map(v => ({ id: v.id }))
    : [{ id: val.id }]
}

function mapFileToCreate(val) {
  return {
    name: val.name || `attachment`,
    filestackId: val.filestackId,
    fileType: val.fileType
  }
}

function mapLocationToUpsert(location) {
  return {
    where: {
      // have to fake this id :/ because we don't have the real id yet
      id: location.id || 'new' + Math.random() * 1000000
    },
    create: mapLocationToCreate(location),
    update: mapLocationToCreate(location)
  }
}

function mapLocationToCreate(val) {
  return {
    name: val.name,
    prettyAddress: val.prettyAddress,
    lat: val.lat,
    lng: val.lng
  }
}

function pluckOtherValue(val) {
  return typeof val === 'object' ? val.other : val
}

function arrayOrSingle(
  value,
  singleKey,
  arrayKey,
  transformArgs,
  normalizeVal
) {
  const isArray = Array.isArray(value)
  return isArray
    ? {
        [arrayKey]: transformArgs
          ? transformArgs(value, isArray)
          : {
              set: value
                .filter(v => v)
                .map(v =>
                  normalizeVal
                    ? normalizeVal(pluckOtherValue(v))
                    : pluckOtherValue(v)
                )
            }
      }
    : {
        [singleKey]: transformArgs
          ? transformArgs(value, isArray)
          : normalizeVal
          ? normalizeVal(pluckOtherValue(value))
          : pluckOtherValue(value)
      }
}

export function parseAndMapWeather(weather) {
  let mappedWeather
  if (
    weather?.tideHeight ||
    weather?.waveHeight ||
    weather?.waterTemp ||
    weather?.waveInterval ||
    weather?.airTemp ||
    weather?.windSpeed ||
    weather?.windDirection
  ) {
    mappedWeather = {
      tideHeight: parseFloatTwoDecimal(weather?.tideHeight),
      waveHeight: parseFloatTwoDecimal(weather?.waveHeight),
      waterTemp: parseFloatTwoDecimal(weather?.waterTemp),
      waveInterval: parseFloatTwoDecimal(weather?.waveInterval),
      airTemp: parseFloatTwoDecimal(weather?.airTemp),
      windSpeed: parseFloatTwoDecimal(weather?.windSpeed),
      windDirection: weather?.windDirection
    }
  }
  return mappedWeather
}

export function mapValuesToSubmitForm(values) {
  let weather = parseAndMapWeather(values.weather)
  const now = new Date().toISOString()
  return {
    formId: values.formId,
    stepId: values.stepId,
    draft: values?.draft || false,
    submissionId: values.submissionId,
    createdAt: values.createdAt || now,
    weather,
    fieldResponses: values.fields.map(field => ({
      formFieldId: field.id,
      fieldItemResponses: field.fieldItems.map(fieldItem => ({
        ...(fieldItem.type === 'text' &&
          arrayOrSingle(fieldItem.value, 'text', 'multiText')),
        ...(fieldItem.type === 'number' &&
          arrayOrSingle(
            fieldItem.value,
            'number',
            'multiNumber',
            null,
            val => parseFloat(val)
          )),
        ...(fieldItem.type === 'dateTime' && {
          dateTime: fieldItem.value
        }),
        ...(fieldItem.type === 'boolean' && {
          boolean: fieldItem.value
        }),
        formFieldItem: {
          connect: {
            id: fieldItem?.formFieldItem?.id || fieldItem.id
          }
        },
        ...(fieldItem.type === 'file' &&
          fieldItem.value &&
          arrayOrSingle(
            fieldItem.value,
            'files',
            'files',
            (val, isArray) => ({
              create: isArray
                ? val
                    .filter(f => !f.removed)
                    .map(v => mapFileToCreate(v))
                : [mapFileToCreate(val)]
            })
          )),
        ...(fieldItem.type === 'locations' &&
          fieldItem.value &&
          arrayOrSingle(
            fieldItem.value,
            'locations',
            'locations',
            (val, isArray) => ({
              create: isArray
                ? val
                    .filter(f => !f.removed)
                    .map(v => mapLocationToCreate(v))
                : [mapLocationToCreate(val)]
            })
          )),
        ...(fieldItem.type === 'users' &&
          fieldItem.value &&
          arrayOrSingle(
            fieldItem.value,
            'users',
            'users',
            (val, isArray) => ({
              connect: mapArgsArrayOrSingleToConnectId(val, isArray)
            })
          )),
        ...(fieldItem.type === 'positions' &&
          fieldItem.value &&
          arrayOrSingle(
            fieldItem.value,
            'positions',
            'positions',
            (val, isArray) => ({
              connect: mapArgsArrayOrSingleToConnectId(val, isArray)
            })
          )),
        ...(fieldItem.type === 'assets' &&
          fieldItem.value &&
          arrayOrSingle(
            fieldItem.value,
            'assets',
            'assets',
            (val, isArray) => ({
              connect: mapArgsArrayOrSingleToConnectId(val, isArray)
            })
          ))
      }))
    }))
  }
}
