/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Flex } from 'theme-ui'
import Button from './Button'
import Icon from '../Icon'

export const CloseModalButton = p => {
  return (
    <Button
      type="button"
      variant="naked"
      sx={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        fontWeight: 'bold',
        zIndex: 1,
        borderRadius: '50%',
        padding: '.25rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray400',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          bg: 'gray300',
          borderColor: 'gray400'
        }
      }}
      {...p}
    >
      <Icon icon="close" height={25} width={25} />
    </Button>
  )
}

const ModalHeader = (
  {
    children,
    variant = 'ModalHeader',
    toggle,
    customCloseButton,
    elipse,
    ...props
  },
  ref
) => {
  const ExternalCloseBtn = customCloseButton || (
    <CloseModalButton onClick={toggle} />
  )
  return (
    <Flex variant={variant} sx={{ marginBottom: 3 }} {...props}>
      <h5
        sx={{
          marginBottom: '0px',
          lineHeight: 1.5,
          width: '97%',
          ...(elipse && {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          })
        }}
      >
        {children}
      </h5>
      {ExternalCloseBtn}
    </Flex>
  )
}

export default forwardRef(ModalHeader)
