/** @jsxImportSource theme-ui */
import { lazy, Suspense, useState } from 'react'

import last from 'lodash/last'
import uniqBy from 'lodash/uniqBy'
import { DateRangePicker } from '../../../components/Forms/DatePickerInput'
import Spinner from 'src/images/icons/Spinner'
import {
  GET_MY_AGENCIES_FORM_RESPONSES,
  useGetCurrentAgencyContext
} from '../../../hooks/queries'

import { useQuery } from '@apollo/client'

import FormSubmitRenderer from './formSubmitRenderer'

import { prettyDate, retry } from 'src/utils'
import { Box, Flex } from 'theme-ui'
import { RolesOnly } from 'src/app/services/auth'

import ModalButton from '../../Shared/ModalButton'
import { CenteredContent } from '../..'
import CustomTabs from '../../Shared/CustomTabs'
import moment from 'moment'
import ArchiveFormResponseButton from './ArchiveFormResponseButton'
import Button from '../../Shared/Elements/Button'
import Label from '../../Shared/Elements/Label'
import ListGroup from '../../Shared/Elements/ListGroup'
import ListGroupItem from '../../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../../Shared/Elements/ListGroupItemHeading'
import Input from '../../Shared/Elements/Input'
import Badge from '../../Shared/Elements/Badge'
import isEmpty from 'lodash/isEmpty'
import CustomSelectInput from '../../Forms/CustomSelectInput'
import { FormFieldItemWhereForm } from '../../Reporting/Forms/FormFieldItemWhereForm'

import { RichTextRender } from '../../Shared/RichTextRender'

import { FormResponseSubmitRenderer } from './formSubmitRenderer/FormResponseSubmitRenderer'
import { generateFormResponseAdvancedWhere } from '../../Reporting/Forms/utils'
import { RequestPDFReport } from '../../Reporting/StatReport'

const GenerateFormCSVButtonAsync = lazy(() =>
  retry(() => import('./GenerateFormCSVButton'))
)

const FormResponseList = ({ tabIndex, formId, name, onFinish }) => {
  const { user } = useGetCurrentAgencyContext()

  return (
    <CustomTabs
      activeTabIndex={tabIndex}
      tabs={[
        ...(user?.role !== 'READ_ONLY'
          ? [
              {
                linkTitle: 'Submit Form',
                component: (
                  <FormSubmitRenderer
                    onFinish={onFinish}
                    hideTitle
                    formId={formId}
                  />
                )
              }
            ]
          : []),
        {
          linkTitle: 'Recent Responses',
          component: (
            <FormResponseListItems formId={formId} formName={name} />
          )
        }
      ]}
    />
  )
}

const RequestCSV = ({ filters, formName, formId }) => {
  return (
    <ModalButton
      buttonLabel={<>Download CSV</>}
      buttonProps={{
        variant: 'warning'
      }}
      modalHeader="CSV Download Request"
    >
      {() => {
        return (
          <Suspense fallback={<Spinner />}>
            <GenerateFormCSVButtonAsync
              formName={formName}
              formId={formId}
              where={filters}
            />
          </Suspense>
        )
      }}
    </ModalButton>
  )
}

export const FormResponseListItems = ({
  submissionId,
  emptyState,
  hideSearch,
  emptyHeight,
  formName,
  hideSummary,
  includeFormName,
  formId,
  customComponent,
  suggestedForms,
  includeSummaryTitles = false
}) => {
  const { users } = useGetCurrentAgencyContext()

  const defaultWhere = {
    archived: {
      equals: false
    },
    form: {
      supervisorReviewConfig: null
    },
    ...(submissionId
      ? {
          submission: {
            id: { equals: submissionId }
          }
        }
      : {
          form: {
            id: {
              equals: formId
            }
          }
        })
  }
  const [whereArgs, setWhere] = useState({
    ...defaultWhere,
    includeDrafts: true
  })
  const variables = {
    where: defaultWhere,
    includeSummaryTitles,
    take: 10
  }

  const {
    data,
    fetchMore,
    loading,
    refetch,
    called,
    networkStatus
  } = useQuery(GET_MY_AGENCIES_FORM_RESPONSES, {
    variables,
    fetchPolicy: 'cache-and-network'
  })

  function clearFilters() {
    setWhere(defaultWhere)
  }

  function getCurrentFilters(wA) {
    let filters = {}

    if (wA.search || wA.advancedWhere) {
      filters.OR = []
    }

    if (!wA.includeDrafts) {
      filters.draft = {
        equals: false
      }
    }

    if (wA.includeArchived) {
      filters.archived = {
        equals: true
      }
    }

    if (wA.createdAt) {
      filters.createdAt = {
        gte: wA.createdAt.startDate
          ? moment(wA.createdAt.startDate).toISOString()
          : undefined,
        lte: wA.createdAt.endDate
          ? moment(wA.createdAt.endDate).toISOString()
          : undefined
      }
    }

    if (wA.reportedBy && wA.reportedBy.length > 0) {
      filters.submittedBy = {
        id: {
          in: wA.reportedBy.map(i => i.value)
        }
      }
    }

    if (wA.advancedWhere) {
      filters.OR.push(
        generateFormResponseAdvancedWhere(wA.advancedWhere)
      )
    }

    if (wA.search) {
      const number = parseInt(wA.search)

      filters.OR.push({
        submittedBy: {
          name: {
            contains: wA.search
          }
        }
      })

      filters.OR.push({
        fields: {
          some: {
            AND: [
              {
                fieldItems: {
                  some: {
                    OR: [
                      {
                        text: {
                          mode: 'insensitive',
                          contains: wA.search
                        }
                      },
                      {
                        assets: {
                          some: {
                            name: {
                              mode: 'insensitive',
                              contains: wA.search
                            }
                          }
                        }
                      },
                      {
                        positions: {
                          some: {
                            name: {
                              mode: 'insensitive',
                              contains: wA.search
                            }
                          }
                        }
                      },
                      {
                        users: {
                          some: {
                            name: {
                              mode: 'insensitive',
                              contains: wA.search
                            }
                          }
                        }
                      }
                    ].concat(
                      !!number
                        ? [
                            {
                              number: {
                                equals: number
                              }
                            }
                          ]
                        : []
                    )
                  }
                }
              }
            ]
          }
        }
      })
    }
    return filters
  }
  return (
    <>
      {!hideSearch && (
        <Flex
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 20
          }}
        >
          <Flex
            sx={{
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px'
            }}
          >
            <Box
              sx={{
                minWidth: '400px'
              }}
            >
              <Label>Search</Label>
              <Input
                placeholder="Search by answer text, user or resource/location."
                value={whereArgs.search || ''}
                onChange={e => {
                  setWhere({
                    ...whereArgs,
                    search: e.target.value
                  })
                }}
              />
            </Box>
            <Box>
              <Label>Submit Date</Label>
              <Flex
                sx={{
                  minWidth: '200px'
                }}
              >
                <DateRangePicker
                  id="range"
                  value={whereArgs.createdAt || {}}
                  onChange={selected => {
                    setWhere({
                      ...whereArgs,
                      createdAt: selected
                    })
                  }}
                  maxDate={new Date()}
                />
              </Flex>
            </Box>
            <Box
              sx={{
                minWidth: '200px'
              }}
            >
              <Label htmlFor="sort">Reported By</Label>
              <CustomSelectInput
                id="reportedBy"
                value={whereArgs.reportedBy || []}
                isMulti={true}
                isSearchable={true}
                isClearable={true}
                onChange={selected => {
                  if (!selected || selected.length < 1) {
                    return setWhere({
                      ...whereArgs,
                      reportedBy: []
                    })
                  }
                  setWhere({
                    ...whereArgs,
                    reportedBy: selected
                  })
                }}
                options={users?.map(i => ({
                  value: i.id,
                  label: i.name
                }))}
              />
            </Box>
            <Box
              sx={{
                minWidth: '100px'
              }}
            >
              <Input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                id={'includeDrafts'}
                name={'includeDrafts'}
                checked={whereArgs?.includeDrafts || false}
                readOnly
                label={'Include Drafts'}
                onClick={() => {
                  setWhere({
                    ...whereArgs,
                    includeDrafts: !whereArgs?.includeDrafts
                  })
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: '100px'
              }}
            >
              <Input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                id={'includeArchived'}
                name={'includeArchived'}
                checked={whereArgs?.includeArchived || false}
                readOnly
                label={'Include Archived'}
                onClick={() => {
                  setWhere({
                    ...whereArgs,
                    includeArchived: !whereArgs?.includeArchived
                  })
                }}
              />
            </Box>
          </Flex>
          <ModalButton
            buttonLabel={
              <>
                Advanced Search
                {whereArgs?.advancedWhere
                  ? ` (${whereArgs.advancedWhere.length})`
                  : ''}
              </>
            }
            buttonProps={{
              icon: 'plus',
              minWidth: '100%',
              my: '10px',
              variant: whereArgs.advancedWhere
                ? 'primary'
                : 'linkGray'
            }}
            contentSize={'lg'}
          >
            {({ toggle }) => {
              return (
                <FormFieldItemWhereForm
                  formId={formId}
                  hideSubmitterFilter
                  value={whereArgs.advancedWhere || []}
                  where={whereArgs.advancedWhere || []}
                  handleCancel={toggle}
                  onClear={() => {
                    setWhere({
                      ...whereArgs,
                      advancedWhere: null
                    })
                  }}
                  handleFinish={({ where }) => {
                    setWhere({
                      ...whereArgs,
                      advancedWhere: where?.some(w => !isEmpty(w))
                        ? where
                        : null
                    })

                    toggle()
                  }}
                />
              )
            }}
          </ModalButton>
          <Flex
            sx={{
              gap: '5px',
              width: '100%',
              py: '20px',
              justifyContent: 'space-between'
            }}
          >
            <Flex sx={{ gap: '10px' }}>
              <Button
                variant="primary"
                sx={{ minWidth: 100 }}
                onClick={() => {
                  const filters = getCurrentFilters(whereArgs)

                  refetch({
                    where: {
                      ...filters,
                      ...defaultWhere
                    }
                  })
                }}
              >
                {loading ? <Spinner white /> : 'Search'}
              </Button>
              <Button
                variant="secondary"
                sx={{ minWidth: 100 }}
                onClick={() => {
                  clearFilters()
                }}
              >
                Clear Filters
              </Button>
            </Flex>
            <RequestCSV
              formName={formName}
              formId={formId}
              filters={getCurrentFilters(whereArgs)}
            />
          </Flex>
        </Flex>
      )}
      {!data ||
      (data.myAgenciesFormResponses.length < 1 &&
        (!customComponent || !suggestedForms?.length)) ? (
        emptyState || (
          <CenteredContent height={emptyHeight || '200px'}>
            {loading && <Spinner />}
            No Form Responses
          </CenteredContent>
        )
      ) : customComponent ? (
        customComponent({
          data,
          called,
          refetch,
          suggestedForms,
          submissionId
        })
      ) : (
        <>
          <ListGroup>
            {data.myAgenciesFormResponses.map(fr => (
              <FormResponseItem
                key={fr.id}
                variables={variables}
                hideSummary={hideSummary}
                includeFormName={includeFormName}
                {...fr}
              />
            ))}
          </ListGroup>
          {data &&
            data.myAgenciesFormResponses.length > 0 &&
            data.myAgenciesFormResponses.length % 10 === 0 && (
              <Button
                variant="primary"
                sx={{ margin: 'auto', marginTop: '10px' }}
                disabled={loading || networkStatus === 3}
                icon={loading || networkStatus === 3 ? 'spinner' : ''}
                onClick={() =>
                  fetchMore({
                    variables: {
                      cursor: {
                        id:
                          data &&
                          last(data.myAgenciesFormResponses).id
                      },
                      skip: 1
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev

                      return Object.assign({}, prev, {
                        myAgenciesFormResponses: uniqBy(
                          [
                            ...prev.myAgenciesFormResponses,
                            ...fetchMoreResult.myAgenciesFormResponses
                          ],
                          function(e) {
                            return e.id
                          }
                        )
                      })
                    }
                  })
                }
              >
                Load more
              </Button>
            )}{' '}
        </>
      )}
    </>
  )
}

export const FormResponseItem = ({
  onClick,
  hideEdit,
  variables,
  hideSummary,
  includeFormName,
  cacheQuery,
  editMode,
  hideIncidentLink,
  icon,
  draft,
  onDraftFinish,
  onFinish,
  sx,
  ...fr
}) => {
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const toggleResponseModal = () => {
    setOpenResponseModal(!openResponseModal)
  }

  return (
    <>
      <ListGroupItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          cursor: onClick && 'pointer',
          ...sx
        }}
        onClick={onClick}
      >
        <Box sx={{ flex: '1 1 80%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Box>
              <ModalButton
                isControlled={true}
                modalIsOpen={openResponseModal}
                onModalChange={setOpenResponseModal}
                buttonProps={{
                  variant: 'danger',
                  sx: {
                    padding: '0px',
                    minWidth: '100%'
                  }
                }}
                CustomButtonComponent={props => (
                  <Box {...props}>
                    {draft && (
                      <Badge
                        size="sm"
                        sx={{ mb: '10px' }}
                        variant="warning"
                      >
                        Draft
                      </Badge>
                    )}
                    {includeFormName && (
                      <ListGroupItemHeading
                        sx={{
                          gap: '10px',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        {icon} {fr?.form?.name} {fr.nameSuffix}
                      </ListGroupItemHeading>
                    )}
                    <Box>
                      {fr.submittedBy.name} on{' '}
                      {prettyDate(fr.createdAt)}
                    </Box>
                    {!hideIncidentLink && fr?.submissionId && (
                      <Box sx={{ mt: '15px' }}>
                        <Button
                          linkProps={{
                            target: '_blank',
                            rel: 'noopener noreferrer'
                          }}
                          size="sm"
                          icon="linked"
                          variant="warning"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          href={`/agency/stats?incidentId=${fr?.submissionId}`}
                        >
                          Incident Report
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
                contentSize={'lg'}
              >
                {({ toggle }) => {
                  return (
                    <FormResponseSubmitRenderer
                      formResponseId={fr.id}
                      formId={fr?.form?.id}
                      onFinish={() => {
                        onFinish && onFinish()
                        toggle()
                      }}
                      onDraftFinish={onDraftFinish}
                      tableView={!editMode && !draft}
                      includeReporting
                    />
                  )
                }}
              </ModalButton>

              {!hideSummary && (
                <p sx={{ margin: '0px', mt: 10 }}>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Flex
                      sx={{
                        gap: '15px',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start'
                      }}
                    >
                      {fr.summary &&
                        fr.summary.map((s, k) => (
                          <Box sx={{ width: '150px', bg: 'gray100' }}>
                            <RichTextRender
                              key={k}
                              mw={'150px'}
                              text={s || '-'}
                            />
                          </Box>
                        ))}
                    </Flex>
                  </Flex>
                </p>
              )}
            </Box>
          </Flex>
        </Box>

        <RolesOnly
          roles={['ADMIN', 'DISPATCHER', 'SUPERVISOR']}
          ownerId={fr.submittedBy.id}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2
            }}
          >
            <Button variant="primary" onClick={toggleResponseModal}>
              View Response
            </Button>
            {!hideEdit && (
              <>
                <ModalButton
                  buttonLabel="Report"
                  buttonProps={{ variant: 'warning' }}
                  isValidToggle={() => {
                    return true
                  }}
                >
                  {({ toggle }) => {
                    return (
                      <RequestPDFReport
                        reportName={fr?.form?.name}
                        formResponseId={fr.id}
                        onClose={toggle}
                      />
                    )
                  }}
                </ModalButton>
                <ArchiveFormResponseButton
                  variables={variables}
                  id={fr.id}
                  isArchived={fr.archived}
                  cacheQuery={cacheQuery}
                />
              </>
            )}
          </Flex>
        </RolesOnly>
      </ListGroupItem>
    </>
  )
}

export default FormResponseList
